.ant-picker-dropdown {
    .ant-picker-panel-layout {
        display: flex;
        flex-direction: column;
    
        .ant-picker-presets {
            width: 100%;
            max-width: none;
    
            ul {
                display: flex;
                align-items: center;
                height: auto;
                flex-direction: row;
                max-width: 288px;
                flex-wrap: wrap;

                li {
                    &+li {
                        margin-top: 0;
                    }
                }
            }
        }

        .ant-picker-panels {
            flex-direction: column;
        }
    }
}


@media all and (min-width: 768px) {
    .ant-picker-dropdown {
        .ant-picker-panel-layout {
            flex-direction: row;
        
            .ant-picker-presets {
                width: auto;
                max-width: none;
        
                ul {
                    display: block;
                    height: 0;
                    max-width: none;
    
                    li {
                        &+li {
                            margin-top: 8px;
                        }
                    }
                }
            }
    
            .ant-picker-panels {
                flex-direction: row;
            }
        }
    }
}