$lightGrey: #d3d3d3;
$darkTeal: #0c3945;

.checkin-form {
    .user-response, .coach-response {
        font-weight: bold;
        padding: 10px;
        border-radius: 5px;
    }
    .user-response {
        background-color: $lightGrey;
    }

    .coach-response {
        margin-top: 20px;
        background-color: $darkTeal;
        color: white;
    }
}