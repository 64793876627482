header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    background-color: rgba(255, 255, 255, 0.9);

    .app-header-logo {
        width: 150px;
    }

    nav {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.9);

        ul {
            display: flex;
            align-items: center;
            gap: 1rem;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: block;

                a {
                    display: block;
                    text-decoration: none;

                    &:before {
                        transition: background-color .3s;
                    }

                    &:hover {
                        &:before {
                            background-color: rgba(#666, .1)!important;
                        }
                    }
                    
                    &.active {
                        &:before {
                            background-color: rgba(#666, .1)!important;
                        }
                    }
                }
            }

            &.main-nav {
                li {
                    a {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }
                }
            }

            &.sub-nav {
                margin-left: auto;
            }
        }
    }
}

@media all and (min-width: 768px) {
    header {
        flex-direction: row;
    }
}