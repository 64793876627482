$lightGray: #f0f0f0;
$medLightGray: #f4f4f4;
$darkTeal: #0c3945;

.messages {
    h1 {
        margin-top: 0;
    }
}

.messages-wrap {
    padding: 0;

    .ant-card-body {
        padding: 0;
    }
}

@keyframes loadingDots {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
}

.messages-container {
    flex-direction: column;
    gap: 1rem;

    .contact-list-wrap {
        position: relative;
        border-bottom: solid 1px $lightGray;
        height: 73px;
        overflow: hidden;
        transition: transform .25s;

        &.open {
            height: auto;
            border: solid 1px darken($lightGray, 10%);
            transform: scale(1.025);
            border-radius: 8px;
            overflow: hidden;
        }

        .mobile-list-toggle {
            border: 0;
            padding: 0;
            margin: 0;
            display: block;
            background: none;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            &.open {
                display: none;
                z-index: -1;
            }
        }

        .contact-list {
            .contact {
                padding: 0;
                background-color: #fff;
                border-radius: 8px 8px 0 0;

                &.active {
                    background-color: $medLightGray;
                }

                .contact-link {
                    display: block;
                    width: 100%;
                    padding: 12px;
                }

                .ant-list-item-meta-description {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .divider {
        display: none;

        .ant-divider-vertical {
            margin-inline: 0;
        }
    }

    .messages-wrap{
        padding: 0;

        .conversation {
            position: relative;

            .loading {
                position: sticky;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                z-index: 999;

                .dot {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: $darkTeal;
                    width: 7px;
                    height: 7px;
                    border-radius: 99px;
                    animation-name: loadingDots;
                    animation-duration: .5s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate-reverse;
                    
                    &:nth-child(1) {
                        transform: translate(calc(-50% - 12px), -50%);
                        animation-delay: .1s;
                    }
                    
                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                        animation-delay: .25s;
                    }
                    
                    &:nth-child(3) {
                        transform: translate(calc(-50% + 12px), -50%);
                        animation-delay: .4s;
                    }
                }
            }
        }
    }
}

@media all and (min-width: 768px) {
    .messages-wrap {
        overflow: hidden;
    }

    .messages-container {
        flex-direction: row;
        gap: 0;

        .contact-list-wrap {
            width: 35%;
            border-bottom: 0;
            height: auto;
            overflow: visible;

            .mobile-list-toggle {
                display: none;
                z-index: -1;
            }

            .contact-list {
                .contact {
                    border-radius: 8px 0 0 8px;
                }
            }
        }

        .divider {
            display: block;
        }

        .messages-wrap{
            width: 65%;

            .conversation {
                background-color: $medLightGray;
                position: relative;
                width: 100%;
                height: 50vh;
                overflow-y: auto;
            }
        }
    }
}

@media all and (min-width: 1024px) {
    .messages-container {
        .contact-list-wrap {
            width: 25%;
        }

        .messages-wrap{
            width: 75%;
        }
    }
}