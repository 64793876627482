@use './scss/header';
@use './scss/userlog';
@use './scss/messages';
@use './scss/date-picker';
@use './scss/weekly-checkins';

$darkTeal: #0c3945;
$limeGreen: #c0e064;
$lightGrey: rgba(0, 0, 0, 0.15);
$red: #EC564F;
$orange: #F89719;
$green: #8db40b;
$blue: #1e8eab;
$lightGrey: #d3d3d3;
$mediumGrey: #7d7f7c;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;700&display=swap');

body,
html {
    margin: 0;
    padding: 0;
    color: $darkTeal;
    box-sizing: border-box;
    min-height: 100vh;;
}

* {
    box-sizing: inherit;
}

body {
    position: relative;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'Noto Sans', sans-serif;
    background: linear-gradient(0deg, #fff, #fff, #cdc2b5);
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    footer {
        margin-top: auto;
    }
}

.blue { color: $blue; }
.green { color: $green; }
.red { color: $red; }
.orange { color: $orange; }
.light-grey { color: $lightGrey }
.medium-grey { color: $mediumGrey }

a {
    color: #0c3945
}

img {
    max-width: 100%;
    height: auto;
}

.content-header {
    font-weight: 400;
    line-height: 1.1;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    opacity: 0;
}

.app-logo {
    display: block;
    max-width: 356px;
    margin: 0 auto 1rem;
}

.content-wrap {
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto 0;
    padding: 0 2rem;
}

.wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto 0;
    height: 100%;
    padding: 0 2rem;

    .text {
        margin-bottom: 2rem;
    }

    .app-preview {
        margin-bottom: 2rem;

        .iphone {
            position: relative;

            .screenshot {
                display: block;
                width: 100%;
                max-width: 340px;
                margin: 0 auto;
            }
        }
    }
}

.app-store-links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 2rem;

    img {
        align-self: center;
        max-width: 150px;
    }
}

.privacy-policy {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;

    .close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        z-index: 999;
        padding: 10px;
        line-height: 1;
        transition: background-color .25s;

        &:hover {
            background-color: rgba($darkTeal, .1);
        }
    }
}

.terms-service {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;

    .close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        z-index: 999;
        padding: 10px;
        line-height: 1;
        transition: background-color .25s;

        &:hover {
            background-color: rgba($darkTeal, .1);
        }
    }
}

//todo: create login page
.login {
    // position: relative;
    width: calc(100% - 2rem);
    max-width: 420px;
    margin: 4rem auto;
    position: absolute; /* or absolute */
    top: 50%;
    left: 50%;
    margin-top: -20px;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;
    //box-shadow: 10px 10px 5px $darkTeal;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    p {
        margin-top: -15px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        z-index: 999;
        padding: 10px;
        line-height: 1;
        transition: background-color .25s;

        &:hover {
            background-color: rgba($darkTeal, .1);
        }
    }

    .form-controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .remember {
            span:not(.ant-checkbox) {
                white-space: nowrap;
            }
        }
    }
}

.support {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;

    p {
        margin-top: -15px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        z-index: 999;
        padding: 10px;
        line-height: 1;
        transition: background-color .25s;

        &:hover {
            background-color: rgba($darkTeal, .1);
        }
    }
}

.users, .messages, .userLog, .programSignup, .weeklyCheckins, .applications {
    position: relative;
    width: 100%;
    // margin: auto;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
}

.users {
    min-height: calc(100% - 2rem);
}

.messages, .userLog, .programSignup, .weeklyCheckins {
    max-width: 1200px;   
}

.messages {
    .message-bubble {
        max-width: 85%; 
        margin: 10px;
    }
    .message-bubble-edit {
        width: 85%; 
        margin: 10px;
    }
    .message-bubble-audio {
        min-width: 50%; 
        margin: 10px;
    }
    .message-sent {
        float: right; 
    }
    .message-received {
        float: left; 
    }
    .message-sent-bubble {
        background-color: rgb(12, 57, 69, .85); 
        color: white;
        padding: 10px; 
        border-radius: 7px;
    }
    .message-received-bubble {
        background-color: $lightGrey; 
        padding: 10px; 
        border-radius: 7px;
    }
    .chat-container {
        width: '100%';
        padding: 10px;
        border-radius: 7px;
        background-color: white;
        border-width: 1px;
        border-style: solid;
        border-color: $lightGrey;
    }
    .recent-msg-unread {
        border-left-style: solid;
        border-left-width: 5px;
        border-left-color: green;
    }
}

.nutritionInfo {
    .calories { color: $orange; font-weight: bold; }
    .protein { color: $green; font-weight: bold; }
    .carbs { color: $blue; font-weight: bold; }
    .fats { color: $red; font-weight: bold; }
}

footer {
    background: rgba(#000, .45);

    .footer-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        padding: 1rem 2rem;
        margin: 0 auto;

        .copy {
            color: #fff;

            .folink {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        a {
            color: $limeGreen;
            font-weight: bold;
        }

        button {
            color: $limeGreen;
            font-weight: bold;
            text-decoration: underline;
        }

        .social-links {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;

            a {
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}

.fixed-footer {
    position: 'fixed'; bottom: 0; right: 0; left: 0;
}

@media all and (min-width: 768px) {
    body {
        background-image: url(./img/bg-faded.jpg);
        background-size: cover;
        background-attachment: fixed;
    }

    .content-header {
        font-size: 3.25rem;
    }

    .wrap {
        flex-direction: row;

        .text {
            width: 70%;
            padding-right: 2rem;
        }

        .app-preview {
            width: 30%;
        }
    }

    footer {
        .footer-wrap {
            flex-direction: row;
        }
    }
}